import React, { useState } from "react"
import axios from "axios"
import { graphql } from "gatsby"
import {RichText } from 'prismic-reactjs'
import ReactModal from 'react-modal'
import linkResolver from '../utils/linkResolver'
import Layout from '../components/layout'
import SEO from '../components/seo'
import InlineSVG from '../components/inlineSVG'

import Shapes from '../components/shapes'
import '../components/findYourSurvey.scss'


ReactModal.setAppElement('#___gatsby');


const FindYourSurveyPage = ({ data }) => {

  const [showModal, setShowModal] = useState(false);

  function handleOpenModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
    document.querySelector(".kb__fys-email-field form").reset();
  }

  function sendEmail(e){
    e.preventDefault();

    var backerEmail = document.querySelector("#fys-email").value

    axios.post('/pledge-manager/find_survey',{
      "email": backerEmail
    })
    .then(function (response) {
      handleOpenModal();
    })
    .catch(function (error) {
      
      var errorMessage = document.querySelector('.kb__error-message');
      
      errorMessage.classList.add('kb__error-message--active');

      setTimeout(function(){ 
        errorMessage.classList.remove('kb__error-message--active');
      }, 5000);
    })
  }

  if (!data) return null;
  if (!data.prismic) return null;
  if (!data.prismic.allFind_your_surveys) return null;
  if (!data.prismic.allFind_your_surveys.edges[0]) return null;
  if (!data.prismic.allFind_your_surveys.edges[0].node) return null;

  const content = data.prismic.allFind_your_surveys.edges[0].node

  return(
    <Layout>

    <SEO 
      title={RichText.asText(content.meta_title)}
      description={RichText.asText(content.meta_description)}
      meta={[
        {
          property: `og:image`,
          content: content.meta_image.facebook.url
        },{
          property: `twitter:image`,
          content: content.meta_image.twitter.url
        }
      ]}
    />
    <section className="kb__banner kb__fys-banner">
      <div className="kb__container">
        <div className="kb__banner-content" data-center-layout="true">
          <div className="kb__grid-center">
            <div className="kb__col-9_sm-12">

              {content.body.map((slice,index) => {
                  if(slice.type === "find_your_survey_2"){
                    return(
                      <div key={index} className="">
                        <h1>
                          {slice.primary.fys_title &&
                            slice.primary.fys_title
                          }
                        
                          {slice.primary.fys_title_2 &&
                            <span className="script">{slice.primary.fys_title_2}</span>
                          }
                        </h1>

                        <h3>{slice.primary.fys_sub_title}</h3>

                        <div className="kb__fys-email-field">
                          <form onSubmit={sendEmail}>
                            <input id="fys-email" aria-label="Find your survey email field" name="fys-email" type="email" placeholder={slice.primary.fys_input_placeholder} required/>
                            <button type="submit"  aria-label="Send your email"><InlineSVG url={slice.primary.fys_input_icon.url} /></button>
                          </form>
                          <div className="kb__error-message">
                            {slice.primary.fys_error_message}
                          </div>
                        </div>

                        <ReactModal 
                          isOpen={showModal}
                          contentLabel="Email sendzy"
                          onRequestClose={handleCloseModal}
                          className="kb__modal kb__fys-modal"
                          overlayClassName="kb__overlay kb__overlay--white"
                        >
                          
                          
                          <div className="kb__modal-title">
                            <img src={slice.primary.fys_modal_icon.url} alt={slice.primary.fys_modal_icon.alt}/>
                            <h4>{slice.primary.fys_modal_title}</h4>
                          </div>

                          <div className="kb__modal-body">
                            {RichText.render(slice.primary.fys_modal_rich_text, linkResolver)}

                          </div>

                          <div className="kb__modal-footer">
                            <button onClick={handleCloseModal}>{slice.primary.fys_modal_close_text}</button>
                          </div>

                        </ReactModal>
                      </div>
                    )
                  } else{
                    return null;
                  }
              
              })

              }
            </div>
          </div>
        </div>
      </div>

      <Shapes />

    </section>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allFind_your_surveys {
        edges {
          node {
            _meta {
              uid
              type
              tags
            }
            _linkType
            body {
              ... on PRISMIC_Find_your_surveyBodyFind_your_survey_2 {
                type
                label
                primary {
                  fys_input_icon
                  fys_input_placeholder
                  fys_modal_close_text
                  fys_modal_icon
                  fys_modal_rich_text
                  fys_modal_title
                  fys_sub_title
                  fys_title
                  fys_title_2
                  fys_error_message
                }
              }
            }
            meta_description
            meta_image
            meta_title
            title
          }
        }
      }
    }
  }
`

export default FindYourSurveyPage

